import * as React from "react"
import { graphql } from 'gatsby'

import IndexPageTemplate from "../components/pageTemplates/indexTemplate";

// markup
const IndexPageDe = ({pageContext, data}) => {
 pageContext.locale = 'de' 
    return (
      <IndexPageTemplate pageContext={pageContext}
                         data={data}
                         title='Brandeis Consulting - SAP Training und SAP Schulungen von Profis'
                         excperpt='Relevantes SAP Wissen aus der Praxis für Ihr Projekt! Vom Autor des Buches "SQLScript für SAP HANA"'/>  
    )
  }
  
  export default IndexPageDe

export const query = graphql`
  query Trainings_de {
    allMdx(
      filter: {frontmatter: {Type: {eq: "Training"}}}
    ) {
      nodes {
        frontmatter {
          Excerpt
          Title
          Slogan 
          Slug
          Locale
          cssClass
          Level
          Duration
          Events{
            StartDate
          }
          Image{
            childImageSharp {
              gatsbyImageData(width: 500, height: 300)
            }
          }
        }
      }
    }
  }
`